import React from "react";
import ReactToPrint from "react-to-print";
import Grid from "@mui/material/Grid";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
  Typography,
  FormControlLabel,
  Box,
  Paper,
} from "@mui/material";
import moment from "moment";

const styles = {
  printContainer: {
    width: "100%",
  },
  page: {
    height: "297mm",
    width: "210mm",
    margin: "0",
    padding: "15mm",
    position: "relative",
    // paddingTop: "10px" // Added this line
  },
  lastPage: {
    height: "297mm",
    width: "210mm",
    margin: "0",
    padding: "10mm",
    position: "relative",
    pageBreakAfter: "auto",
    paddingTop: "10px", // Added this line
  },
  header: {
    position: "absolute",
    width: "100%",
    top: "0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "stretch",
  },
  headerRow: {
    margin: "0", // Remove space between rows
  },
  headerColumn1: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "10px",
  },
  headerColumn2: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
  },
  headerColumn3: {
    flex: 1,
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  footer: {
    position: "absolute",
    width: "100%",
    bottom: "-10",
    textAlign: "center",
  },
  content: {
    marginTop: "50px",
    marginBottom: "50px",
  },
  boldText: {
    fontWeight: "bold",
  },
  logo: {
    width: "50%",
    height: "auto",
    padding: "10px",
  },
  additionalFields: {
    marginTop: "100px",
    marginBottom: "20px",
    alignItems: "center",
    padding: "40px",
  },
  additionalField: {
    marginBottom: "10px",
    width: "300px",
    alignItems: "center",
  },
};

const headings = {
  contactInfo: "Contact Information",
  TradeLicenseInfo: "Customer Due Diligence (CDD)",
  shareHolders: "Shareholders",
  financialInfo: "Financial Information",
};

class EntityPrint extends React.Component {
  renderHeader(user) {
    console.log("user:", user);
    return (
      <div style={styles.header}>
        <Box
          className="rectangle-3"
          sx={{
            height: "63px",
            left: "580px",
            position: "absolute",
            top: "11px",
            width: "280px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "left",
            padding: "10px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <Typography
              component="span"
              variant="body2"
              sx={{ fontWeight: "bold", fontSize: "12px" }}
            >
              Company Name:
            </Typography>
            <Typography
              component="span"
              variant="body2"
              sx={{ fontSize: "9px" }}
            >
              {user.name}
              {/* {user.name} */}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "", gap: "6px" }}>
            <Typography
              component="span"
              variant="body2"
              sx={{ fontWeight: "bold", fontSize: "12px" }}
            >
              Address:
            </Typography>
            <Typography
              component="span"
              variant="body2"
              sx={{ fontSize: "9px" }}
            >
              {user.address}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Typography
              component="span"
              variant="body2"
              sx={{ fontWeight: "bold", fontSize: "12px" }}
            >
              FIU Org ID:
            </Typography>
            <Typography
              component="span"
              variant="body2"
              sx={{ fontSize: "12px" }}
            >
              {user.fiuOrgID}
            </Typography>
          </Box>
        </Box>

        <Box
          className="rectangle-4"
          sx={{
            //backgroundColor: "#ff1809",
            height: "55px",
            left: "332px",
            position: "absolute",
            top: "0px",
            width: "152px",
          }}
        >
          <img
            className="logo"
            alt="Logo"
            src={`https://amldiligence.com/${user.logo || "logo.png"}`}
            style={{
              height: "100px",
              left: "0",
              objectFit: "contain",
              position: "absolute",
              top: "0px",
              width: "100px",
            }}
          />
        </Box>
        <Box
          className="overlap-2"
          sx={{
            // backgroundColor: "#f7f7f7",
            height: "63px",
            left: "0px",
            position: "absolute",
            top: "11px",
            width: "182px",
          }}
        >
          <Box
            className="text-wrapper-3"
            sx={{
              color: "#000000",
              fontFamily: "lucida-console",
              fontSize: "12px",
              fontWeight: "100",
              left: "0px",
              letterSpacing: "0",
              lineHeight: "normal",
              position: "absolute",
              top: "32px",
            }}
          >
            www.amldiligence.com
          </Box>
          <p
            className="p"
            style={{
              color: "#000000",
              fontFamily: '"Inter-Regular", Helvetica',
              fontSize: "9px",
              fontWeight: "400",
              left: "0",
              letterSpacing: "0",
              lineHeight: "normal",
              position: "absolute",
              top: "35px",
            }}
          >
            {/* Consulted by MHR Chartered Acoountants */}
          </p>
          <img
            className="logo"
            alt="Logo"
            src="http://app.amldiligence.com/logo.png"
            style={{
              height: "24px",
              left: "0",
              objectFit: "cover",
              position: "absolute",
              top: "8px",
              width: "116px",
            }}
          />
        </Box>
      </div>
    );
  }

  renderContent(section) {
    const { data } = this.props;
    if (section === "shareHolders") {
      return (
        <div>
          {data[section].map((shareHolder, index) => (
            <Box alignContent="center" paddingBottom={"30px"}>
              <tr
                height="19"
                textAlign="center"
                style={{ borderBottom: "1px solid gray" }}
              >
                <td colSpan="5" height="19">
                  <b>
                    Shareholder # {index + 1}{" "}
                    {shareHolder.ubo ? (
                      <small>
                        <i>UBO</i>
                      </small>
                    ) : (
                      ""
                    )}{" "}
                    {shareHolder.isAuthorizedSignatory ? (
                      <small>
                        <i>Authorized Signatory</i>
                      </small>
                    ) : (
                      ""
                    )}
                  </b>
                </td>
              </tr>
              <tr height="12">
                <td height="12" style={{ fontSize: "10px", padding: "5px" }}>
                  <b>Full legal name</b>
                </td>
                <td style={{ fontSize: "10px", padding: "5px" }}>
                  <b>Nationality</b>
                </td>
                <td style={{ fontSize: "10px", padding: "5px" }}>
                  <b>Date of Birth</b>
                </td>
                <td>
                  <b style={{ fontSize: "10px", padding: "5px" }}>
                    % of Shareholding
                  </b>
                </td>
                <td style={{ fontSize: "10px", padding: "5px" }}>
                  <b>Date of becoming shareholder</b>
                </td>
              </tr>
              <tr height="12">
                <td height="12" style={{ fontSize: "10px", padding: "5px" }}>
                  {shareHolder.fullLegalName}
                </td>
                {/* Assuming nationality and date of becoming shareholder are available */}
                <td style={{ fontSize: "10px", padding: "5px" }}>
                  {" "}
                  {shareHolder.nationality}
                </td>
                <td style={{ fontSize: "10px", padding: "5px" }}>
                  {moment(shareHolder.doB).format("DD-MM-YYYY")}
                </td>
                <td style={{ fontSize: "10px", padding: "5px" }}>
                  {shareHolder.percentShares}
                </td>
                <td style={{ fontSize: "10px", padding: "5px" }}>
                  {shareHolder.dateOfBecomingShareholder}
                </td>
              </tr>
              <tr height="12">
                <td height="12" style={{ fontSize: "10px", padding: "5px" }}>
                  <b>Residential Status</b>
                </td>
                <td style={{ fontSize: "10px", padding: "5px" }}>
                  <b>ID Doc type</b>
                </td>
                <td style={{ fontSize: "10px", padding: "5px" }}>
                  <b>ID Issue Country</b>
                </td>
                <td style={{ fontSize: "10px", padding: "5px" }}>
                  <b>ID Doc No</b>
                </td>
                <td style={{ fontSize: "10px", padding: "5px" }}>
                  <b>ID Expiry Date</b>
                </td>
              </tr>
              <tr height="31">
                <td height="31" style={{ fontSize: "12px", padding: "5px" }}>
                  {shareHolder.residentialStatus}
                </td>
                <td style={{ fontSize: "12px", padding: "5px" }}>
                  {shareHolder.idDoc.type}
                </td>
                <td style={{ fontSize: "12px", padding: "5px" }}>
                  {shareHolder.idDoc.issueCountry}
                </td>
                <td style={{ fontSize: "12px", padding: "5px" }}>
                  {shareHolder.idDoc.number}
                </td>
                <td style={{ fontSize: "12px", padding: "5px" }}>
                  {moment(shareHolder.idDoc.expiryDate).format("DD-MM-YYYY")}
                </td>
              </tr>
            </Box>
          ))}
        </div>
      );
    } else if (section === "TradeLicenseInfo") {
      return (
        <div>
          <table border="0" cellpadding="0" cellspacing="0" width="703">
            <colgroup>
              <col width="211" />
              <col width="172" />
              <col width="129" />
              <col width="140" />
              <col width="51" />
            </colgroup>
            <tbody>
              <tr height="32"></tr>
              <tr height="36">
                <td height="36" width="311">
                  <b> FULL LEGAL NAME</b>{" "}
                  <small>
                    <br />
                    (as per Incorporation Doc)
                  </small>
                </td>
                <td colspan="4">{data.fullLegalName}</td>
              </tr>
              <tr height="33">
                <td height="33">
                  <b>Business Activity</b>
                </td>
                <td>
                  <b>Trade License #</b>
                </td>
                <td width="229">
                  <b>Tax Registration #</b>
                </td>
                <td colspan="2" width="391">
                  <b>Country of Incorporation</b>
                </td>
              </tr>
              <tr height="33">
                <td height="33" width="211">
                  {data.TradeLicenseInfo.businessActivity}
                </td>
                <td>{data.TradeLicenseInfo.tradeLicenseNo}</td>
                <td>{data.taxNo}</td>
                <td colspan="2">
                  {" "}
                  {data.TradeLicenseInfo.countryofIncorporation}
                </td>
              </tr>
              <tr height="33">
                <td height="33">
                  <b>Licensing Authority</b>
                </td>
                <td width="320">
                  <b>Incorporation Date</b>
                </td>
                <td colspan="3" width="320">
                  <b>Registered Address</b>
                </td>
              </tr>
              <tr height="33">
                <td height="33" width="211">
                  {data.TradeLicenseInfo.licensingAuthority}
                </td>
                <td>
                  {moment(data.TradeLicenseInfo.incoroprationDate).format(
                    "DD-MM-YYYY"
                  )}
                </td>
                <td colspan="3">{data.registeredAddress}</td>
              </tr>
              <tr height="33">
                <td height="33">
                  <b>Business Address</b>
                </td>
                <td colspan="4">{data.businessAddress} </td>
              </tr>
              <tr height="39">
                <td
                  colspan="5"
                  height="39"
                  style={{ borderBottom: "1px solid gray" }}
                >
                  <b> CONTACT INFORMATION </b>
                </td>
              </tr>
              <tr height="33">
                <td height="33">
                  <b>Type</b>
                </td>
                <td>
                  <b>Country</b>
                </td>
                <td colspan="0">
                  <b>Number</b>
                </td>
                <td>
                  <b>P.O Box</b>
                </td>
              </tr>
              <tr height="33">
                <td height="33">{data.contactInfo.contactType}</td>
                <td>{data.contactInfo.contactCountry}</td>
                <td colspan="0">{data.contactInfo.contactNumber}</td>
                <td>{data.contactInfo.POBox}</td>
              </tr>
              <tr height="33">
                <td height="33">
                  <b>Email Address</b>
                </td>
                <td>
                  <b>Website</b>
                </td>
              </tr>
              <tr height="33">
                <td height="33">{data.contactInfo.emailAddress}</td>
                <td>{data.contactInfo.website}</td>
              </tr>
              <tr height="39">
                <td
                  colspan="5"
                  height="39"
                  style={{ borderBottom: "1px solid gray" }}
                >
                  <b> EVIDENCE OF SOURCE OF FUNDS</b>{" "}
                  <small>
                    <i>
                      Tick yes or no when signing below, inscribe any other
                      evidence if needed
                    </i>
                  </small>
                </td>
              </tr>
              <tr height="33">
                <td height="33" width="150px">
                  <b> Annual Audited Accounts</b>
                </td>
                <td>Yes/No</td>
                <td>
                  <b>Bank statement</b>
                </td>
                <td colspan="2">Yes/No</td>
              </tr>
              <tr height="133">
                <td height="33">
                  <b>any other evidence</b>
                </td>
                <td colspan="4"></td>
              </tr>
              <tr height="33">
                <td height="33" colspan="3">
                  {/* <b> Customer Self-Declaration </b> */}
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr height="20">
                <td colspan="5" rowspan="3" height="141" width="703">
                  <small>
                    {" "}
                    <i>
                      {/* I hereby declare that the information contained in this
                      FORM is accurate and update; and undertake to provide
                      further information to abide by the rules and regulations
                      of Anti-Money Laundry Law in force in the State. <br />I
                      understand that I am required to declare the source of
                      funds that I will be using for the purpose. I understand
                      the requirements of the Federal Decree-Law No. (20) of
                      2018 On Anti-Money Laundering and Combatting the Financing
                      of of Terrorism and Financing of Illegal Organisations and
                      the Cabinet Decision No. (10) of 2019 Concerning the
                      Implementing Regulation of Decree Law no. (20) of 2018 on
                      Anti-Money Laundering and Combating the Financing of
                      Terrorism and Illegal Organisations and do hereby
                      undertake that the source of funds/metals are acquired
                      from legitimate sources and evidence of such is available
                      if needed or as requested. I/We do hereby undertake that
                      the funds/metals do not originate from any sanctioned
                      country/entity/person/s from the United Nations and other
                      relevant sanction programs. */}
                    </i>
                  </small>
                </td>
              </tr>
              <tr height="12"></tr>
              <tr height="109"></tr>
              <tr height="5">
                <td colspan="5" height="5" width="703"></td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    } else if (section === "financialInfo") {
      return (
        <Grid
          container
          spacing={3}
          sx={{ marginTop: "20px", marginBottom: "20px", paddingLeft: "50px" }}
        >
          <table border="0" cellpadding="0" cellspacing="0" width="635">
            <colgroup>
              <col width="211" />
              <col width="233" />
              <col width="140" />
              <col width="51" />
            </colgroup>
            <tbody>
              <tr height="36">
                <td height="36" width="211">
                  <b>Bank Name</b>
                </td>
                <td colspan="3" width="424">
                  {data.financialInfo.bankName}
                </td>
              </tr>
              <tr height="36">
                <td height="36">
                  <b>Account Name</b>
                </td>
                <td>
                  <b>Account No</b>
                </td>
                <td colspan="2" width="191">
                  <b>IBAN</b>
                </td>
              </tr>
              <tr height="31">
                <td height="31" width="11">
                  {data.financialInfo.accountName}
                </td>
                <td>{data.financialInfo.accountNo}</td>
                <td colspan="2">{data.financialInfo.iban}</td>
              </tr>
              <tr height="31">
                <td height="31">
                  <b>BIC/SWIFT</b>
                </td>
                <td width="400">
                  <b>Currency of Account</b>
                </td>
                <td colspan="2" width="491">
                  <b> Year of Relationship with Bank </b>
                </td>
              </tr>
              <tr height="61">
                <td height="61" width="411">
                  {data.financialInfo.bicSwift}
                </td>
                <td>{data.financialInfo.currency}</td>
                <td colspan="2">{data.financialInfo.yearOfRelationship}</td>
              </tr>
              <tr height="44">
                <td height="44" width="411">
                  <b> Bank Branch Name, Town, City </b>
                </td>
                <td colspan="3">{data.financialInfo.branch}</td>
              </tr>
              <tr height="19">
                <td colspan="4" height="44">
                  <b>
                    <u>Financial Records</u>{" "}
                  </b>
                </td>
              </tr>
              <tr height="40">
                <td height="44" width="211">
                  <b>Expected Annual Activity with the Company (AED)</b>
                </td>
                <td colspan="3">{data.financialInfo.anualBusiness}</td>
              </tr>
              <tr height="21">
                <td height="44">
                  <b>Total Share capital</b>
                </td>
                <td colspan="3">{data.financialInfo.totalShareCapital}</td>
              </tr>
              <tr height="21">
                <td height="44">
                  <b>Total Shareholders equity</b>
                </td>
                <td colspan="3">{data.financialInfo.totalShareHolderEquity}</td>
              </tr>
              <tr height="21">
                <td height="44">
                  <b>Total Assets</b>
                </td>
                <td colspan="3">{data.financialInfo.totalAssets}</td>
              </tr>
              <tr height="21">
                <td height="44">
                  <b>Latest Annual Sales</b>
                </td>
                <td colspan="3">{data.financialInfo.totalAnualSales}</td>
              </tr>
            </tbody>
          </table>
        </Grid>
      );
    }
  }

  renderFooter(index) {
    return (
      <div style={styles.footer}>
        Page {index + 1} of 3 <br />{" "}
        <small>Printed on: {moment().format("DD-MM-YYYY")} </small>
      </div>
    );
  }

  render() {
    const { data } = this.props;
    const user = JSON.parse(localStorage.getItem("DashBoardUser"));

    return (
      <div style={styles.printContainer}>
        {[
          "TradeLicenseInfo",
          // "contactInfo",
          "shareHolders",
          // "Ultimate Benificial Owners",
          // "Authorized Signatory",
          "financialInfo",
        ].map((section, index, arr) => (
          <div
            style={
              index === arr.length - 1
                ? styles.lastPage
                : { ...styles.page, pageBreakAfter: "always" }
            }
            key={index}
          >
            {this.renderHeader(user)}

            <div style={styles.content}>
              <Grid
                container
                spacing={3}
                paddingLeft={"50px"}
                paddingTop={"20px"}
              >
                <Grid xs={12} textAlign={"center"}>
                  <div
                    sx={{
                      textAlign: "center",
                      // fontSize: "24px",
                      //   paddingBottom: "100px",
                      position: "relative",
                    }}
                  >
                    <h2>{headings[section]}</h2>
                  </div>
                </Grid>
                {/* {Object.keys(data[section] || {}).map((key, i) => (
                    <Grid item xs={4} key={i}>
                      <strong>{key}:</strong> {data[section][key]}
                    </Grid>
                  ))} */}
                {this.renderContent(section)}
              </Grid>

              <Grid
                container
                // spacing={"5px"}
                style={styles.additionalFields}
                paddingTop={"90px"}
                gap={"70px"}
              >
                <Grid item xs={3} borderTop={"1px solid"}>
                  <div style={styles.additionalField}>
                    MLRO signature*
                    <br />
                    <small>for office use only</small>
                  </div>
                </Grid>
                <Grid item xs={3} borderTop={"1px solid"}>
                  <div style={styles.additionalField}>
                    Senior Management Approval
                    <br />
                    <small>for office use only</small>
                  </div>
                </Grid>
                <Grid item xs={3} borderTop={"1px solid"}>
                  <div style={styles.additionalField}>
                    Client Signature*
                    <br />
                    <small>authorised person</small>
                  </div>
                </Grid>
              </Grid>
            </div>
            {this.renderFooter(index)}
          </div>
        ))}
      </div>
    );
  }
}

export default EntityPrint;
