import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
  // Grid,
  // Box,
  // Typography,
  // FormControlLabel,
  // Dialog,
  DialogContent,
  // DialogTitle,
  // DialogActions,
  // Button,
  Box,
  Fab,
  FormControlLabel,
  Button,
  Checkbox,
  TextField,
  Grid,
  Dialog,
  DialogActions,
  InputLabel,
  Select,
  DialogTitle,
  Typography,
  Divider,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { transformShareHolders, transformData } from "./utils";
import { countryNames } from "data/data";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ReactToPrint from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";
import { useLocation } from "react-router-dom";
import PrintTable from "components/printTable";
import moment from "moment";
import { OpenInBrowser } from "@mui/icons-material";

const countryMenuItems = countryNames.map((country) => (
  <MenuItem value={`${country}`} key={country}>
    {country}
  </MenuItem>
));

const styles = {
  printContainer: {
    width: "100%",
  },
  page: {
    height: "1090px",
    width: "1024px",
    margin: "0",
    padding: "15mm",
    position: "relative",
    // paddingTop: "10px" // Added this line
  },
  lastPage: {
    height: "297mm",
    width: "210mm",
    margin: "0",
    padding: "10mm",
    position: "relative",
    pageBreakAfter: "auto",
    paddingTop: "10px", // Added this line
  },
  header: {
    position: "absolute",
    width: "100%",
    top: "0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "stretch",
  },
  headerRow: {
    margin: "0", // Remove space between rows
  },
  headerColumn1: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "10px",
  },
  headerColumn2: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
  },
  headerColumn3: {
    flex: 1,
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  footer: {
    position: "absolute",
    width: "100%",
    bottom: "-10",
    textAlign: "center",
  },
  content: {
    marginTop: "50px",
    marginBottom: "50px",
  },
  boldText: {
    fontWeight: "bold",
  },
  logo: {
    width: "50%",
    height: "auto",
    padding: "10px",
  },
  additionalFields: {
    marginTop: "100px",
    marginBottom: "20px",
    alignItems: "center",
    padding: "40px",
  },
  additionalField: {
    marginBottom: "10px",
    width: "300px",
    alignItems: "center",
  },
  noPrint: {
    "@media print": {
      display: "none",
    },
  },
};

const headings = {
  contactInfo: "Contact Information",
  TradeLicenseInfo: "Customer Due Diligence (CDD)",
  shareHolders: "Shareholders",
  financialInfo: "Financial Information",
};

const ViewKyc = ({ open, onClose, data }) => {
  const user = JSON.parse(localStorage.getItem("DashBoardUser"));

  const [fields, setFields] = useState([]);
  // console.log("Received in editEntity.jsx" + JSON.stringify(data._id));
  console.log("Data is", data);
  //   const [editEntity] = useEditCustomersMutation();

  const initialValues = {
    _id: data?._id,
    legalName: data?.fullLegalName || "",
    screeningResult: data?.screeningResult || "", // Assuming this field exists in your data
    registeredAddress: data?.registeredAddress || "",
    businessActivity: data?.TradeLicenseInfo.businessActivity || "",
    tradeLicenseNo: data?.TradeLicenseInfo.tradeLicenseNo || "",
    taxNo: data?.taxNo || "",
    countryofIncorporation: data?.TradeLicenseInfo.countryofIncorporation || "",
    licensingAuthority: data?.TradeLicenseInfo.licensingAuthority || "",
    businessAddress: data?.businessAddress || "",
    incoroprationDate:
      moment(data?.TradeLicenseInfo.incoroprationDate)
        .utc()
        .format("DD-MM-YYYY") || "",
    licenseExpiry:
      moment(data?.TradeLicenseInfo.licenseExpiry).utc().format("DD-MM-YYYY") ||
      "",
    contactType: data?.contactInfo.contactType || "",
    contactCountry: data?.contactInfo.contactCountry || "",
    contactNumber: data?.contactInfo.contactNumber || "",
    emailAddress: data?.contactInfo.emailAddress || "",
    jurisdiction: data?.jurisdiction || "", // Assuming this field exists in your data
    POBox: data?.contactInfo.POBox || "",
    website: data?.contactInfo.website || "",
    bankName: data?.financialInfo.bankName || "",
    accountNo: data?.financialInfo.accountNo || "",
    accountName: data?.financialInfo.accountName || "",
    iban: data?.financialInfo.iban || "",
    bicSwift: data?.financialInfo.bicSwift || "",
    currency: data?.financialInfo.currency || "",
    yearOfRelationship: data?.financialInfo.yearOfRelationship || "",
    branch: data?.financialInfo.branch || "",
    anualBusiness: data?.financialInfo.anualBusiness || "",
    totalShareCapital: data?.financialInfo.totalShareCapital || "",
    totalShareHolderEquity: data?.financialInfo.totalShareHolderEquity || "",
    totalAssets: data?.financialInfo.totalAssets || "",
    totalAnualSales: data?.financialInfo.totalAnualSales || "",
    sourceOfFunds: data?.sourceOfFunds || "", // Assuming this field exists in your data
    shareHolders: data?.shareHolders || [],

    // _id: data?._id,
    // legalName: data?.fullLegalName,
    // registeredAddress: data?.registeredAddress,
    // businessActivity: data?.TradeLicenseInfo.businessActivity,
    // tradeLicenseNo: data?.TradeLicenseInfo.tradeLicenseNo,
    // taxNo: data?.taxNo,
    // countryofIncorporation: data?.TradeLicenseInfo.countryofIncorporation,
    // licensingAuthority: data?.TradeLicenseInfo.licensingAuthority,
    // businessAddress: data?.businessAddress,
    // incoroprationDate: moment(data?.TradeLicenseInfo.incoroprationDate)
    //   .utc()
    //   .format("YYYY-MM-DD"),
    // licenseExpiry: moment(data?.TradeLicenseInfo.licenseExpiry)
    //   .utc()
    //   .format("YYYY-MM-DD"),
    // contactType: data?.contactInfo.contactType,
    // contactCountry: data?.contactInfo.contactCountry,
    // contactNumber: data?.contactInfo.contactNumber,
    // emailAddress: data?.contactInfo.emailAddress,
    // POBox: data?.contactInfo.POBox,
    // website: data?.contactInfo.website,
    // bankName: data?.financialInfo.bankName,
    // accountNo: data?.financialInfo.accountNo,
    // accountName: data?.financialInfo.accountName,
    // iban: data?.financialInfo.iban,
    // bicSwift: data?.financialInfo.bicSwift,
    // currency: data?.financialInfo.currency,
    // yearOfRelationship: data?.financialInfo.yearOfRelationship,
    // branch: data?.financialInfo.branch,
    // anualBusiness: data?.financialInfo.anualBusiness,
    // totalShareCapital: data?.financialInfo.totalShareCapital,
    // totalShareHolderEquity: data?.financialInfo.totalShareHolderEquity,
    // totalAssets: data?.financialInfo.totalAssets,
    // totalAnualSales: data?.financialInfo.totalAnualSales,
    // shareHolders: data?.shareHolders,
    // Add more initial values for other fields here
  };

  //   const initialValues = {
  //     _id: data._id,
  //     legalName: data.fullLegalName || "",
  //     screeningResult: data.screeningResult || "", // Assuming this field exists in your data
  //     registeredAddress: data.registeredAddress || "",
  //     businessActivity: data.TradeLicenseInfo.businessActivity || "",
  //     tradeLicenseNo: data.TradeLicenseInfo.tradeLicenseNo || "",
  //     taxNo: data.taxNo || "",
  //     countryofIncorporation: data.TradeLicenseInfo.countryofIncorporation || "",
  //     licensingAuthority: data.TradeLicenseInfo.licensingAuthority || "",
  //     businessAddress: data.businessAddress || "",
  //     incoroprationDate:
  //       moment(data.TradeLicenseInfo.incoroprationDate)
  //         .utc()
  //         .format("YYYY-MM-DD") || "",
  //     licenseExpiry:
  //       moment(data.TradeLicenseInfo.licenseExpiry).utc().format("YYYY-MM-DD") ||
  //       "",
  //     contactType: data.contactInfo.contactType || "",
  //     contactCountry: data.contactInfo.contactCountry || "",
  //     contactNumber: data.contactInfo.contactNumber || "",
  //     emailAddress: data.contactInfo.emailAddress || "",
  //     jurisdiction: data.jurisdiction || "", // Assuming this field exists in your data
  //     POBox: data.contactInfo.POBox || "",
  //     website: data.contactInfo.website || "",
  //     bankName: data.financialInfo.bankName || "",
  //     accountNo: data.financialInfo.accountNo || "",
  //     accountName: data.financialInfo.accountName || "",
  //     iban: data.financialInfo.iban || "",
  //     bicSwift: data.financialInfo.bicSwift || "",
  //     currency: data.financialInfo.currency || "",
  //     yearOfRelationship: data.financialInfo.yearOfRelationship || "",
  //     branch: data.financialInfo.branch || "",
  //     anualBusiness: data.financialInfo.anualBusiness || "",
  //     totalShareCapital: data.financialInfo.totalShareCapital || "",
  //     totalShareHolderEquity: data.financialInfo.totalShareHolderEquity || "",
  //     totalAssets: data.financialInfo.totalAssets || "",
  //     totalAnualSales: data.financialInfo.totalAnualSales || "",
  //     sourceOfFunds: data.sourceOfFunds || "", // Assuming this field exists in your data
  //     shareHolders: data.shareHolders || [],
  //   };
  const [formValues, setFormValues] = useState(initialValues);

  const handleChange = (event) => {
    const { id, value } = event.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [id]: value,
    }));
    console.log(id + ";" + value);
  };
  const handleDropdownChange = (event) => {
    console.log(event.target);
    const { name, value } = event.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
    console.log(name + ";" + value);
  };

  useEffect(() => {
    var newSh = [];
    initialValues?.shareHolders?.map((sh, index) => {
      var _shareHolder = {
        id: index,
        fullName: sh.fullLegalName,
        occupation: sh.occupation,
        nationality: sh.nationality, // Just added
        dualNationality: sh.dualNationality,
        idType: sh.idDoc.type,
        country: sh.idDoc.issueCountry,
        number: sh.idDoc.number,
        issueDate: moment(sh.idDoc.issueDate).utc().format("YYYY-MM-DD"),
        expiryDate: moment(sh.idDoc.expiryDate).utc().format("YYYY-MM-DD"),
        birthDate: moment(sh.doB).utc().format("YYYY-MM-DD"),
        residentialStatus: sh.residentialStatus,
        percentShares: sh.percentShares,
        contactType: sh.contactInfo.contactType,
        contactCountry: sh.contactInfo.contactCountry,
        contactNumber: sh.contactInfo.contactNumber,
        authorizedSignatory: sh.isAuthorizedSignatory,
        ubo: sh.ubo,
        uboReason: sh.uboReason,
        uboDate: moment(sh.UboDate).utc().format("YYYY-MM-DD"),
      };
      newSh.push(_shareHolder);
    });
    setFields(newSh);
  }, [data]);

  const addField = () => {
    const newField = {
      id: fields.length + 1,
      fullName: "",
      idType: "",
      country: "",
      number: "",
      issueDate: "",
      expiryDate: "",
      birthDate: "",
      residentialStatus: "",
      percentShares: "",
      contactType: "",
      contactCountry: "",
      contactNumber: "",
      authorizedSignatory: false,
      ubo: false,
      uboReason: "",
      uboDate: "",
    };
    setFields([...fields, newField]);
  };

  const handleFieldChange = (id, key, value) => {
    const updatedFields = fields?.map((field) =>
      field.id === id ? { ...field, [key]: value } : field
    );
    console.log(key + ";" + value);
    setFields(updatedFields);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const transformedValues = transformData(formValues);
    const transformedShHolders = fields.map((f) => {
      return transformShareHolders(f);
    });
    formValues.shareHolders = [...fields];
    transformedValues.shareHolders = [...transformedShHolders];
    console.log(transformedValues);
    console.log(formValues);
    // editEntity(transformedValues);
    onClose();
  };

  const removeField = (index) => {
    const filteredFields = [...fields];
    filteredFields.splice(index, 1);
    setFields(filteredFields);
  };
  console.log("reg Address", initialValues.registeredAddress);
  return data ? (
    <div>
      <Dialog
        open={open}
        onClose={onclose}
        // Prevents dialog from closing when clicking outside
        fullWidth
        maxWidth="lg"
        scroll={"paper"}
      >
        {/* <DialogTitle>
          {" "}
          <Typography
            variant="h4"
            paddingBottom={"12px"}
            color={"#ffc76b"}
            fontWeight={"bold"}
          >
            KYC Details
          </Typography>
        </DialogTitle> */}
        <DialogContent>
          <Grid
            container={true}
            columnSpacing={3}
            columns={{ xs: 4, sm: 8, md: 12 }}
            padding="45px"
            paddingTop={0}
          >
            <Grid item xs={5}>
              <InputLabel>Legal Name</InputLabel>
              <TextField
                margin="dense"
                id="legalName"
                value={initialValues.legalName}
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item xs={7}>
              <InputLabel>Registered Address</InputLabel>
              <TextField
                margin="dense"
                id="registeredAddress"
                value={initialValues.registeredAddress}
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item xs={8}>
              <InputLabel>Business Address</InputLabel>
              <TextField
                margin="dense"
                id="businessAddress"
                value={initialValues.businessAddress}
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Tax Reg. No.</InputLabel>
              <TextField
                margin="dense"
                id="taxNo"
                value={initialValues.taxNo}
                type="text"
                fullWidth
              />{" "}
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={5}>
                <InputLabel>Source of Funds</InputLabel>{" "}
                <TextField
                  margin="dense"
                  id="taxNo"
                  value={initialValues.sourceOfFunds}
                  type="text"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                fontWeight={"bold"}
                paddingTop={"5px"}
                paddingBottom={"5px"}
                color={"#ffc76b"}
              >
                Trade License Info
              </Typography>{" "}
              <Divider color={"#ffc76b"} />
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Business Activity</InputLabel>{" "}
              <TextField
                margin="dense"
                id="taxNo"
                value={initialValues.businessActivity}
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Trade Lic. No.</InputLabel>
              <TextField
                margin="dense"
                id="tradeLicenseNo"
                value={initialValues.tradeLicenseNo}
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Country of incorporation</InputLabel>
              <TextField
                autoFocus
                margin="dense"
                name="countryofIncorporation"
                defaultValue={initialValues.countryofIncorporation}
                fullWidth
                placeholder="Country of Incorporation"
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Licensing Authority</InputLabel>
              <TextField
                margin="dense"
                id="licensingAuthority"
                value={initialValues.licensingAuthority}
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Jurisdiction</InputLabel>
              <TextField
                margin="dense"
                name="jurisdiction"
                defaultValue={initialValues.jurisdiction}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Incorporation Date</InputLabel>
              <TextField
                id="incoroprationDate"
                value={initialValues.incoroprationDate}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <InputLabel>Lic. Expiry Date</InputLabel>
              <TextField
                id="licenseExpiry"
                defaultValue={initialValues.licenseExpiry}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                fontWeight={"bold"}
                paddingTop={"5px"}
                paddingBottom={"5px"}
                color={"#ffc76b"}
              >
                Contact Info
              </Typography>{" "}
              <Divider color={"#ffc76b"} />
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Contact Type</InputLabel>
              <TextField
                margin="dense"
                name="contactType"
                defaultValue={initialValues.contactType}
                fullWidth
                placeholder="Contact Type"
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Contact Country</InputLabel>
              <TextField
                margin="dense"
                name="contactCountry"
                defaultValue={initialValues.contactCountry}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Number</InputLabel>
              <TextField
                margin="dense"
                id="contactNumber"
                type="text"
                value={initialValues.contactNumber}
                fullWidth
                placeholder="Number"
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Email</InputLabel>
              <TextField
                margin="dense"
                id="emailAddress"
                type="text"
                value={initialValues.emailAddress}
                fullWidth
                placeholder="Email Address"
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel>PO Box</InputLabel>
              <TextField
                margin="dense"
                id="POBox"
                type="text"
                value={initialValues.POBox}
                fullWidth
                placeholder="PO Box"
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Website</InputLabel>
              <TextField
                margin="dense"
                id="website"
                type="text"
                value={initialValues.website}
                fullWidth
                placeholder="Website"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                fontWeight={"bold"}
                paddingTop={"5px"}
                paddingBottom={"5px"}
                color={"#ffc76b"}
              >
                Financial Info
              </Typography>{" "}
              <Divider color={"#ffc76b"} paddingBottom={"5px"} />
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Bank Name </InputLabel>
              <TextField
                margin="dense"
                id="bankName"
                type="text"
                value={initialValues.bankName}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Account no. </InputLabel>
              <TextField
                margin="dense"
                id="accountNo"
                type="text"
                value={initialValues.accountNo}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Account name </InputLabel>
              <TextField
                margin="dense"
                id="accountName"
                type="text"
                value={initialValues.accountName}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel>IBAN </InputLabel>
              <TextField
                margin="dense"
                id="iban"
                type="text"
                value={initialValues.iban}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel>BIC/SWIFT</InputLabel>
              <TextField
                margin="dense"
                id="bicSwift"
                type="text"
                value={initialValues.bicSwift}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Currency of Account </InputLabel>
              <TextField
                margin="dense"
                id="currency"
                type="text"
                value={initialValues.currency}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Year of relationship with bank </InputLabel>
              <TextField
                margin="dense"
                id="yearOfRelationship"
                type="text"
                value={initialValues.yearOfRelationship}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Bank Br Name, City, Town </InputLabel>
              <TextField
                margin="dense"
                id="branch"
                type="text"
                value={initialValues.branch}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Expected Annual Business with coompany </InputLabel>
              <TextField
                margin="dense"
                id="anualBusiness"
                type="text"
                value={initialValues.anualBusiness}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Total Share Capital </InputLabel>
              <TextField
                margin="dense"
                id="totalShareCapital"
                type="text"
                value={initialValues.totalShareCapital}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Total Shareholder equity </InputLabel>
              <TextField
                margin="dense"
                id="totalShareHolderEquity"
                type="text"
                value={initialValues.totalShareHolderEquity}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Total assets</InputLabel>
              <TextField
                margin="dense"
                id="totalAssets"
                type="text"
                value={initialValues.totalAssets}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Latest Annual Sales </InputLabel>
              <TextField
                autoFocus
                margin="dense"
                id="totalAnualSales"
                type="text"
                value={initialValues.totalAnualSales}
                fullWidth
              />
            </Grid>
            {
              <Grid item xs={12}>
                {" "}
                {/* <Typography paddingTop={"10px"} variant="h5">
                  Share Holders
                </Typography>
                <Divider /> */}
                {fields.length > 0 && (
                  <Typography
                    paddingTop={"10px"}
                    variant="h5"
                    fontWeight={"bold"}
                    color={"#ffc76b"}
                  >
                    Share Holders
                    <Divider color={"#ffc76b"} />
                  </Typography>
                )}
              </Grid>
            }
            {/* Third Section */}
            {fields.map((field, index) => (
              <Grid
                sx={{
                  p: 2,
                  backgroundColor: "#FFFDE9",
                  margin: "auto",
                  title: "home",
                  borderRadius: "10px",
                }}
                container="true"
                columnSpacing={3}
                columns={{ xs: 4, sm: 8, md: 12 }}
                paddingTop={1}
                paddingLeft={"40px"}
                key={index}
              >
                {" "}
                <Grid item xs={3}>
                  <InputLabel id={`fullName-label-${field.id}`}>
                    Full Name
                  </InputLabel>
                  <TextField value={field.fullName} fullWidth margin="normal" />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel id={`idType-label-${field.id}`}>
                    ID Type
                  </InputLabel>
                  <TextField
                    labelId={`idType-label-${field.id}`}
                    value={field.idType}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel id={`idType-label-${field.id}`}>
                    Issue Country
                  </InputLabel>
                  <TextField
                    labelId={`country-label-${field.id}`}
                    value={field.country}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel id={`nationality-label-${field.id}`}>
                    Nationality
                  </InputLabel>
                  <Select
                    labelId={`nationality-label-${field.id}`}
                    value={field.nationality}
                    defaultValue={""}
                    required
                    onChange={(event) =>
                      handleFieldChange(
                        field.id,
                        "nationality",
                        event.target.value
                      )
                    }
                    fullWidth
                    margin="normal"
                  >
                    {countryMenuItems}
                  </Select>
                </Grid>
                <Grid item xs={3}>
                  <InputLabel id={`number-label-${field.id}`}>
                    Number
                  </InputLabel>
                  <TextField value={field.number} fullWidth margin="normal" />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel id={`issueDate-label-${field.id}`}>
                    Issue Date
                  </InputLabel>
                  <TextField
                    type="date"
                    value={field.issueDate}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel id={`issueDate-label-${field.id}`}>
                    Expiry Date
                  </InputLabel>
                  <TextField
                    type="date"
                    required
                    value={field.expiryDate}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel id={`birthDate-label-${field.id}`}>
                    Birth Date
                  </InputLabel>
                  <TextField
                    type="date"
                    value={field.birthDate}
                    required
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel id={`residentialStatus-label-${field.id}`}>
                    Residential Status
                  </InputLabel>
                  <TextField
                    labelId={`residentialStatus-label-${field.id}`}
                    value={field.residentialStatus}
                    onChange={(event) =>
                      handleFieldChange(
                        field.id,
                        "residentialStatus",
                        event.target.value
                      )
                    }
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel id={`percentShares-label-${field.id}`}>
                    Percent Shares
                  </InputLabel>
                  <TextField
                    value={field.percentShares}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel id={`dualNationality-label-${field.id}`}>
                    Dual Nationality{" "}
                  </InputLabel>
                  <TextField
                    labelId={`dualNationality-label-${field.id}`}
                    value={field?.dualNationality}
                    onChange={(event) =>
                      handleFieldChange(
                        field.id,
                        "dualNationality",
                        event.target.value
                      )
                    }
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel id={`occupation-label-${field.id}`}>
                    Occupation{" "}
                  </InputLabel>
                  <TextField
                    labelId={`occupation-label-${field.id}`}
                    value={field.occupation}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    fontWeight={"bold"}
                    paddingBottom={"5px"}
                    paddingTop={"5px"}
                    color={"#ffc76b"}
                  >
                    Contact Info
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <InputLabel id={`contactType-label-${field.id}`}>
                    Contact Type
                  </InputLabel>
                  <TextField
                    labelId={`contactType-label-${field.id}`}
                    value={field.contactType}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputLabel id={`contactCountry-label-${field.id}`}>
                    Contact Country
                  </InputLabel>
                  <TextField
                    labelId={`contactCountry-label-${field.id}`}
                    value={field.contactCountry}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputLabel id={`contactNumber-label-${field.id}`}>
                    Contact Number
                  </InputLabel>
                  <TextField
                    value={field.contactNumber}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={<Checkbox checked={field.authorizedSignatory} />}
                    label="Authorized Signatory"
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={<Checkbox checked={field.ubo} />}
                    label="UBO"
                  />
                </Grid>
                <Grid item xs={4}>
                  {field.ubo && (
                    <>
                      <InputLabel id={`uboReason-label-${field.id}`}>
                        UBO Reason
                      </InputLabel>
                      <TextField
                        labelId={`uboReason-label-${field.id}`}
                        value={field.uboReason}
                        fullWidth
                        margin="normal"
                      />
                    </>
                  )}
                </Grid>
                <Grid item xs={4}>
                  {field.ubo && (
                    <>
                      <TextField
                        label="UBO Date"
                        type="date"
                        value={field.uboDate}
                        required
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </>
                  )}
                </Grid>{" "}
                <Grid item xs={1}></Grid>
              </Grid>
            ))}
            <Grid item xs={12}></Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{}}>
          <Button
            // onClick={onClose}
            onClick={() => {
              setFormValues(null);
              onClose();
              return;
            }}
            color="secondary"
            variant="contained"
            className="noPrint"
          >
            Close
          </Button>

          <div style={{ display: "none" }}>
            {/* <PrintTable ref={componentRef} data={data} /> */}
          </div>
        </DialogActions>{" "}
      </Dialog>
    </div>
  ) : (
    <></>
  );
};

export default ViewKyc;

class EntityPrint extends React.Component {
  renderHeader() {
    const user = JSON.parse(localStorage.getItem("DashBoardUser"));

    return (
      <div style={styles.header}>
        <Box
          className="rectangle-3"
          sx={{
            height: "63px",
            left: "580px",
            position: "absolute",
            top: "11px",
            width: "280px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "left",
            padding: "10px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <Typography
              component="span"
              variant="body2"
              sx={{ fontWeight: "bold", fontSize: "12px" }}
            >
              Company Name:
            </Typography>
            <Typography
              component="span"
              variant="body2"
              sx={{ fontSize: "9px" }}
            >
              {/* {"ARA JEWELLEY LLC"} */}
              {user.name}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "", gap: "6px" }}>
            <Typography
              component="span"
              variant="body2"
              sx={{ fontWeight: "bold", fontSize: "12px" }}
            >
              Address:
            </Typography>
            <Typography
              component="span"
              variant="body2"
              sx={{ fontSize: "9px" }}
            >
              {/* {"Office # 402, Dubai"}
              {"Diamond Plaza, Gold Souk, Deira, Dubai "} */}
              {user.address}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Typography
              component="span"
              variant="body2"
              sx={{ fontWeight: "bold", fontSize: "12px" }}
            >
              FIU Org ID:
            </Typography>
            <Typography
              component="span"
              variant="body2"
              sx={{ fontSize: "12px" }}
            >
              {user.fiuOrgID}
            </Typography>
          </Box>
        </Box>

        <Box
          className="rectangle-4"
          sx={{
            //backgroundColor: "#ff1809",
            height: "55px",
            left: "332px",
            position: "absolute",
            top: "0px",
            width: "152px",
          }}
        >
          <img
            className="logo"
            alt="Logo"
            src={`https://amldiligence.com/${user.logo || "logo123.png"}`}
            style={{
              height: "100px",
              left: "0",
              objectFit: "contain",
              position: "absolute",
              top: "0px",
              width: "100px",
            }}
          />
        </Box>
        <Box
          className="overlap-2"
          sx={{
            // backgroundColor: "#f7f7f7",
            height: "63px",
            left: "0px",
            position: "absolute",
            top: "11px",
            width: "182px",
          }}
        >
          <Box
            className="text-wrapper-3"
            sx={{
              color: "#000000",
              fontFamily: "lucida-console",
              fontSize: "12px",
              fontWeight: "100",
              left: "0px",
              letterSpacing: "0",
              lineHeight: "normal",
              position: "absolute",
              top: "32px",
            }}
          >
            www.amldiligence.com
          </Box>
          <p
            className="p"
            style={{
              color: "#000000",
              fontFamily: '"Inter-Regular", Helvetica',
              fontSize: "9px",
              fontWeight: "400",
              left: "0",
              letterSpacing: "0",
              lineHeight: "normal",
              position: "absolute",
              top: "35px",
            }}
          >
            {/* Consulted by MHR Chartered Acoountants */}
          </p>
          <img
            className="logo"
            alt="Logo"
            src={`https://app.amldiligence.com/logo.png`}
            style={{
              height: "24px",
              left: "0",
              objectFit: "cover",
              position: "absolute",
              top: "8px",
              width: "116px",
            }}
          />
        </Box>
      </div>
    );
  }

  renderContent(section) {
    const data = this.props.data;
    if (section === "shareHolders") {
      return (
        <div>
          {data[section].map((shareHolder, index) => (
            <Box alignContent="center" paddingBottom={"30px"}>
              <tr
                height="19"
                textAlign="center"
                style={{ borderBottom: "1px solid gray" }}
              >
                <td colSpan="5" height="19">
                  <b>
                    Shareholder # {index + 1}{" "}
                    {shareHolder.ubo ? (
                      <small>
                        <i>UBO</i>
                      </small>
                    ) : (
                      ""
                    )}{" "}
                    {shareHolder.isAuthorizedSignatory ? (
                      <small>
                        <i>Authorized Signatory</i>
                      </small>
                    ) : (
                      ""
                    )}
                  </b>
                </td>
              </tr>
              <tr height="12">
                <td height="12" style={{ fontSize: "10px", padding: "5px" }}>
                  <b>Full legal name</b>
                </td>
                <td style={{ fontSize: "10px", padding: "5px" }}>
                  <b>Nationality</b>
                </td>
                <td style={{ fontSize: "10px", padding: "5px" }}>
                  <b>Date of Birth</b>
                </td>
                <td>
                  <b style={{ fontSize: "10px", padding: "5px" }}>
                    % of Shareholding
                  </b>
                </td>
                <td style={{ fontSize: "10px", padding: "5px" }}>
                  <b>Date of becoming shareholder</b>
                </td>
              </tr>
              <tr height="12">
                <td height="12" style={{ fontSize: "10px", padding: "5px" }}>
                  {shareHolder.fullLegalName}
                </td>
                {/* Assuming nationality and date of becoming shareholder are available */}
                <td style={{ fontSize: "10px", padding: "5px" }}>
                  {" "}
                  {shareHolder.nationality}
                </td>
                <td style={{ fontSize: "10px", padding: "5px" }}>
                  {moment(shareHolder.doB).format("DD-MM-YYYY")}
                </td>
                <td style={{ fontSize: "10px", padding: "5px" }}>
                  {shareHolder.percentShares}
                </td>
                <td style={{ fontSize: "10px", padding: "5px" }}>
                  {shareHolder.dateOfBecomingShareholder}
                </td>
              </tr>
              <tr height="12">
                <td height="12" style={{ fontSize: "10px", padding: "5px" }}>
                  <b>Residential Status</b>
                </td>
                <td style={{ fontSize: "10px", padding: "5px" }}>
                  <b>ID Doc type</b>
                </td>
                <td style={{ fontSize: "10px", padding: "5px" }}>
                  <b>ID Issue Country</b>
                </td>
                <td style={{ fontSize: "10px", padding: "5px" }}>
                  <b>ID Doc No</b>
                </td>
                <td style={{ fontSize: "10px", padding: "5px" }}>
                  <b>ID Expiry Date</b>
                </td>
              </tr>
              <tr height="31">
                <td height="31" style={{ fontSize: "12px", padding: "5px" }}>
                  {shareHolder.residentialStatus}
                </td>
                <td style={{ fontSize: "12px", padding: "5px" }}>
                  {shareHolder.idDoc.type}
                </td>
                <td style={{ fontSize: "12px", padding: "5px" }}>
                  {shareHolder.idDoc.issueCountry}
                </td>
                <td style={{ fontSize: "12px", padding: "5px" }}>
                  {shareHolder.idDoc.number}
                </td>
                <td style={{ fontSize: "12px", padding: "5px" }}>
                  {moment(shareHolder.idDoc.expiryDate).format("DD-MM-YYYY")}
                </td>
              </tr>
            </Box>
          ))}
        </div>
      );
    } else if (section === "TradeLicenseInfo") {
      return (
        <div>
          <table border="0" cellpadding="0" cellspacing="0" width="703">
            <colgroup>
              <col width="211" />
              <col width="172" />
              <col width="129" />
              <col width="140" />
              <col width="51" />
            </colgroup>
            <tbody>
              <tr height="32"></tr>
              <tr height="36">
                <td height="36" width="311">
                  <b> FULL LEGAL NAME</b>{" "}
                  <small>
                    <br />
                    (as per Incorporation Doc)
                  </small>
                </td>
                <td colspan="4">{data.fullLegalName}</td>
              </tr>
              <tr height="33">
                <td height="33">
                  <b>Business Activity</b>
                </td>
                <td>
                  <b>Trade License #</b>
                </td>
                <td width="229">
                  <b>Tax Registration #</b>
                </td>
                <td colspan="2" width="391">
                  <b>Country of Incorporation</b>
                </td>
              </tr>
              <tr height="33">
                <td height="33" width="211">
                  {data.TradeLicenseInfo.businessActivity}
                </td>
                <td>{data.TradeLicenseInfo.tradeLicenseNo}</td>
                <td>{data.taxNo}</td>
                <td colspan="2">
                  {" "}
                  {data.TradeLicenseInfo.countryofIncorporation}
                </td>
              </tr>
              <tr height="33">
                <td height="33">
                  <b>Licensing Authority</b>
                </td>
                <td width="320">
                  <b>Incorporation Date</b>
                </td>
                <td colspan="3" width="320">
                  <b>Registered Address</b>
                </td>
              </tr>
              <tr height="33">
                <td height="33" width="211">
                  {data.TradeLicenseInfo.licensingAuthority}
                </td>
                <td>
                  {moment(data.TradeLicenseInfo.incoroprationDate).format(
                    "DD-MM-YYYY"
                  )}
                </td>
                <td colspan="3">{data.registeredAddress}</td>
              </tr>
              <tr height="33">
                <td height="33">
                  <b>Business Address</b>
                </td>
                <td colspan="4">{data.businessAddress} </td>
              </tr>
              <tr height="39">
                <td
                  colspan="5"
                  height="39"
                  style={{ borderBottom: "1px solid gray" }}
                >
                  <b> CONTACT INFORMATION </b>
                </td>
              </tr>
              <tr height="33">
                <td height="33">
                  <b>Type</b>
                </td>
                <td>
                  <b>Country</b>
                </td>
                <td colspan="0">
                  <b>Number</b>
                </td>
                <td>
                  <b>P.O Box</b>
                </td>
              </tr>
              <tr height="33">
                <td height="33">{data.contactInfo.contactType}</td>
                <td>{data.contactInfo.contactCountry}</td>
                <td colspan="0">{data.contactInfo.contactNumber}</td>
                <td>{data.contactInfo.POBox}</td>
              </tr>
              <tr height="33">
                <td height="33">
                  <b>Email Address</b>
                </td>
                <td>
                  <b>Website</b>
                </td>
              </tr>
              <tr height="33">
                <td height="33">{data.contactInfo.emailAddress}</td>
                <td>{data.contactInfo.website}</td>
              </tr>
              <tr height="39">
                <td
                  colspan="5"
                  height="39"
                  style={{ borderBottom: "1px solid gray" }}
                >
                  <b> EVIDENCE OF SOURCE OF FUNDS</b>{" "}
                  <small>
                    <i>
                      Tick yes or no when signing below, inscribe any other
                      evidence if needed
                    </i>
                  </small>
                </td>
              </tr>
              <tr height="33">
                <td height="33" width="150px">
                  <b> Annual Audited Accounts</b>
                </td>
                <td>Yes/No</td>
                <td>
                  <b>Bank statement</b>
                </td>
                <td colspan="2">Yes/No</td>
              </tr>
              <tr height="33">
                <td height="33">
                  <b>any other evidence</b>
                </td>
                <td colspan="4">-----</td>
              </tr>
              <tr height="33">
                <td height="33" colspan="3">
                  <b> Customer Self-Declaration </b>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr height="20">
                <td colspan="5" rowspan="3" height="141" width="703">
                  <small>
                    {" "}
                    <i>
                      I hereby declare that the information contained in this
                      FORM is accurate and update; and undertake to provide
                      further information to abide by the rules and regulations
                      of Anti-Money Laundry Law in force in the State. <br />I
                      understand that I am required to declare the source of
                      funds that I will be using for the purpose. I understand
                      the requirements of the Federal Decree-Law No. (20) of
                      2018 On Anti-Money Laundering and Combatting the Financing
                      of of Terrorism and Financing of Illegal Organisations and
                      the Cabinet Decision No. (10) of 2019 Concerning the
                      Implementing Regulation of Decree Law no. (20) of 2018 on
                      Anti-Money Laundering and Combating the Financing of
                      Terrorism and Illegal Organisations and do hereby
                      undertake that the source of funds/metals are acquired
                      from legitimate sources and evidence of such is available
                      if needed or as requested. I/We do hereby undertake that
                      the funds/metals do not originate from any sanctioned
                      country/entity/person/s from the United Nations and other
                      relevant sanction programs.
                    </i>
                  </small>
                </td>
              </tr>
              <tr height="12"></tr>
              <tr height="109"></tr>
              <tr height="5">
                <td colspan="5" height="5" width="703"></td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    } else if (section === "financialInfo") {
      return (
        <Grid
          container
          spacing={3}
          sx={{ marginTop: "20px", marginBottom: "20px", paddingLeft: "50px" }}
        >
          <table border="0" cellpadding="0" cellspacing="0" width="635">
            <colgroup>
              <col width="211" />
              <col width="233" />
              <col width="140" />
              <col width="51" />
            </colgroup>
            <tbody>
              <tr height="36">
                <td height="36" width="211">
                  <b>Bank Name</b>
                </td>
                <td colspan="3" width="424">
                  {data.financialInfo.bankName}
                </td>
              </tr>
              <tr height="36">
                <td height="36">
                  <b>Account Name</b>
                </td>
                <td>
                  <b>Account No</b>
                </td>
                <td colspan="2" width="191">
                  <b>IBAN</b>
                </td>
              </tr>
              <tr height="31">
                <td height="31" width="11">
                  {data.financialInfo.accountName}
                </td>
                <td>{data.financialInfo.accountNo}</td>
                <td colspan="2">{data.financialInfo.iban}</td>
              </tr>
              <tr height="31">
                <td height="31">
                  <b>BIC/SWIFT</b>
                </td>
                <td width="400">
                  <b>Currency of Account</b>
                </td>
                <td colspan="2" width="491">
                  <b> Year of Relationship with Bank </b>
                </td>
              </tr>
              <tr height="61">
                <td height="61" width="411">
                  {data.financialInfo.bicSwift}
                </td>
                <td>{data.financialInfo.currency}</td>
                <td colspan="2">{data.financialInfo.yearOfRelationship}</td>
              </tr>
              <tr height="44">
                <td height="44" width="411">
                  <b> Bank Branch Name, Town, City </b>
                </td>
                <td colspan="3">{data.financialInfo.branch}</td>
              </tr>
              <tr height="19">
                <td colspan="4" height="44">
                  <b>
                    <u>Financial Records</u>{" "}
                  </b>
                </td>
              </tr>
              <tr height="40">
                <td height="44" width="211">
                  <b>Expected Annual Activity with the Company (AED)</b>
                </td>
                <td colspan="3">{data.financialInfo.anualBusiness}</td>
              </tr>
              <tr height="21">
                <td height="44">
                  <b>Total Share capital</b>
                </td>
                <td colspan="3">{data.financialInfo.totalShareCapital}</td>
              </tr>
              <tr height="21">
                <td height="44">
                  <b>Total Shareholders equity</b>
                </td>
                <td colspan="3">{data.financialInfo.totalShareHolderEquity}</td>
              </tr>
              <tr height="21">
                <td height="44">
                  <b>Total Assets</b>
                </td>
                <td colspan="3">{data.financialInfo.totalAssets}</td>
              </tr>
              <tr height="21">
                <td height="44">
                  <b>Latest Annual Sales</b>
                </td>
                <td colspan="3">{data.financialInfo.totalAnualSales}</td>
              </tr>
            </tbody>
          </table>
        </Grid>
      );
    }
  }

  renderFooter(index) {
    return (
      <div style={styles.footer}>
        Page {index + 1} of 3 <br />{" "}
      </div>
    );
  }

  render() {
    const { data } = this.props.data;
    return (
      <div style={styles.printContainer}>
        {[
          "TradeLicenseInfo",
          // "contactInfo",
          "shareHolders",
          // "Ultimate Benificial Owners",
          // "Authorized Signatory",
          "financialInfo",
        ].map((section, index, arr) => (
          <div
            style={
              index === arr.length - 1
                ? styles.lastPage
                : { ...styles.page, pageBreakAfter: "always" }
            }
            key={index}
          >
            {this.renderHeader()}

            <div style={styles.content}>
              <Grid
                container
                spacing={3}
                paddingLeft={"50px"}
                paddingTop={"20px"}
              >
                <Grid xs={12} textAlign={"center"}>
                  <div
                    sx={{
                      textAlign: "center",
                      // fontSize: "24px",
                      //   paddingBottom: "100px",
                      position: "relative",
                    }}
                  >
                    <h2>{headings[section]}</h2>
                  </div>
                </Grid>
                {/* {Object.keys(data[section] || {}).map((key, i) => (
                      <Grid item xs={4} key={i}>
                        <strong>{key}:</strong> {data[section][key]}
                      </Grid>
                    ))} */}
                {this.renderContent(section)}
              </Grid>

              <Grid
                container
                // spacing={"5px"}
                style={styles.additionalFields}
                paddingTop={"90px"}
                gap={"70px"}
              >
                <Grid item xs={3} borderTop={"1px solid"}>
                  <div style={styles.additionalField}>
                    MLRO signature*
                    <br />
                    <small>for office use only</small>
                  </div>
                </Grid>
                <Grid item xs={3} borderTop={"1px solid"}>
                  <div style={styles.additionalField}>
                    Senior Management Approval
                    <br />
                    <small>for office use only</small>
                  </div>
                </Grid>
                <Grid item xs={3} borderTop={"1px solid"}>
                  <div style={styles.additionalField}>
                    Client Signature*
                    <br />
                    <small>authorised person</small>
                  </div>
                </Grid>
              </Grid>
            </div>
            {this.renderFooter(index)}
          </div>
        ))}
      </div>
    );
  }
}
